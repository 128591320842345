import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Contact from "../components/sections/main/Contact"
import { StaticImage } from "gatsby-plugin-image"

const ContactPage = () => (
  <Layout page="default">
    <StaticImage
      src="../images/contact.jpeg"
      alt="Dave drinking"
      style={{ height: 300, width: "100%" }}
      objectFit="cover"
      objectPosition="50% 50%"
    />
    <Contact formName="Contact fix" />
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => (
  <Seo
    title="CONTACT US | Ask for an offer on extreme sport shows"
    description="Fill the form, tell us about your event and ask an offer for an extreme sports show for your event!"
    slug="/contact"
  />
)

export default ContactPage
