import React from "react"
import styled from "styled-components/macro"
import { pxToRem } from "../../../utils"
import Container from "../../common/Container"
import ContactForm from "../../ContactForm"

const ContactWrapper = styled.section`
  background-color: #000;
  padding: ${pxToRem(100)} 0;
  color: #fff;
`
const ContactTitle = styled.h2`
  font-weight: 400;
  font-size: ${pxToRem(60)};
  font-family: "Teko", sans-serif;
  text-transform: uppercase;
  text-align: center;
  line-height: 0.9;
  margin-top: 0;
  margin-bottom: ${pxToRem(30)}; ;
`
const BoldText = styled.span`
  font-weight: 600;
  font-size: ${pxToRem(60)};
  font-family: "Teko", sans-serif;
`
const ContactAltTitle = styled.h3`
  font-size: ${pxToRem(24)};
  font-weight: 300;
  text-align: center;
  margin-top: 0;
  margin-bottom: ${pxToRem(40)};

  @media all and (min-width: 1025px) {
    font-size: ${pxToRem(28)};
  }
`
const Strong = styled.span`
  font-weight: 700;
`
const AltTag = styled.a`
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  font-style: italic;
`

const Contact = ({ page, formName, ...rest }) => {
  return (
    <ContactWrapper {...rest}>
      <Container>
        <ContactTitle className="wow animate__fadeIn">
          Get in touch and <br />
          <BoldText>book your show!</BoldText>
        </ContactTitle>
        <ContactAltTitle className="wow animate__fadeIn" data-wow-delay=".2s">
          <Strong>Fill the form</Strong> below or message us at{" "}
          <AltTag href="mailto:show@flipunitprod.com">
            show@flipunitprod.com
          </AltTag>
        </ContactAltTitle>
        <ContactForm
          formName={formName}
          mailchimpTags={
            page === "home"
              ? ["Főoldali contact formon iratkozott fel"]
              : ["Contact oldali contact formon iratkozott fel"]
          }
          webhookURL="http://localhost:8888/.netlify/functions/default"
        />
      </Container>
    </ContactWrapper>
  )
}

export default Contact
